import React from "react";

import { Helmet } from "react-helmet";

import { navigate } from "gatsby";

import { useLayoutEffect } from "react";

import AdvisorMenu from "@js/component/AdvisorMenu";
import AdvisorTopBar from "@js/component/AdvisorTopBar";
import Box from "@js/component/Box";
import DashboardView from "@js/component/DashboardView";
import Heading from "@js/component/Heading";
import Main from "@js/component/Main";

import { isLoggedIn } from "@js/services/auth";

import * as classNames from "@css/component/template/portal/Dashboard.module.scss";

/**
 * @const Dashboard
 */
const Dashboard = () => {
    useLayoutEffect(() => {
        if (!(isLoggedIn())) {
            navigate("/portaal/login", { replace: true });
        }
    }, []);

    return (
        <Main>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <AdvisorTopBar />
            <div className={ classNames.container }>
                <section className={ classNames.dashboardPage }>
                    <header className={ classNames.header }>
                        <Heading element="h1" variant="large">
                            Dashboard voor energielabel-adviseurs
                        </Heading>
                        <div className={ classNames.breadcrumbs }>
                            <span className={ classNames.current }>Dashboard</span>
                        </div>
                    </header>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box>
                                <AdvisorMenu />
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <Box>
                                <DashboardView />
                            </Box>
                        </div>
                    </div>
                </section>
            </div>
        </Main>
    );
};

export default Dashboard;
