import { SetOpenAction } from "@js/actions/statusActions";
import { SetProfileCompleteAction } from "@js/actions/statusActions";

import { SET_OPEN } from "@js/actionTypes/statusActionTypes";
import { SET_PROFILE_COMPLETE } from "@js/actionTypes/statusActionTypes";

/**
 * @const setOpen
 */
export const setOpen = (open: number): SetOpenAction => ({
    payload: {
        open: open
    },
    type: SET_OPEN
});

/**
 * @const setProfileComplete
 */
export const setProfileComplete = (profileComplete: boolean): SetProfileCompleteAction => ({
    payload: {
        profileComplete: profileComplete
    },
    type: SET_PROFILE_COMPLETE
});
